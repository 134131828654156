import * as React from "react";
import { FC } from "react";
import { PolandEntrypoint } from "../../../entrypoints/poland";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout
    lang={LANG.plPL}
    entrypoint={"poland"}
    page={"z_podrozy_po_polsce"}
  >
    <PolandEntrypoint lang={LANG.plPL} />
  </DefaultLayout>
);
